import React from "react";

interface IDashboardProps {
  onPressed: () => void;
}

const Dashboard = ({ onPressed }: IDashboardProps) => {
  const pages = [
    "Inicio",
    "Torneos",
    "Mesas",
    "Mi Perfil",
    "Pagos",
    "Recargas",
  ];
  const items = Array.from({ length: 10 });

  return (
    <section>
      <header>
        <img src="./assets/logo.png" alt="logo" />
        <img className="floating" src="./assets/line.png" alt="line" />
        <img src="./assets/balance.png" alt="balance" />
      </header>
      <div>
        <section>
          {pages.map((page, index) => {
            return (
              <div className="page-item" key={index}>
                <span>{page}</span>
              </div>
            );
          })}

          <img src="./assets/banner.png" alt="banner" />
        </section>
        <section>
          {items.map((_, index) => {
            return (
              <>
                <div onClick={() => onPressed()}>
                  <img src="./assets/line-1.png" alt="line-1" />
                </div>

                <div onClick={() => onPressed()}>
                  <img src="./assets/line-2.png" alt="line-2" />
                </div>

                <div onClick={() => onPressed()}>
                  <img src="./assets/line-3.png" alt="line-3" />
                </div>
              </>
            );
          })}
        </section>
      </div>
    </section>
  );
};

export default Dashboard;
