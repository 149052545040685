import React from "react";

interface ILoginProps {
  onPressed: () => void;
}

const Login = ({ onPressed }: ILoginProps) => {
  return (
    <section>
      <img src="./assets/logo.png" alt="logo" />
      <form className="form">
        <div className="container-form">
          <label htmlFor="email">Email:</label>
          <input id="email" type="text" />
        </div>
        <div className="container-form">
          <label htmlFor="password">Password:</label>
          <input id="password" type="password" />
        </div>
      </form>
      <button className="btn btn-primary" onClick={() => onPressed()}>
        Entrar
      </button>
    </section>
  );
};

export default Login;
