import React from "react";
import { renderUnicodeSuitSymbol } from "../../utils/ui";

const Card = (props) => {
  const {
    cardData: { suit, cardFace, animationDelay },
    applyFoldedClassname,
  } = props;

  const convertImage = (suit) => {
    const image = renderUnicodeSuitSymbol(suit);
    return <img src={image.src} alt="card" />;
  };

  return (
    <div
      key={`${suit} ${cardFace}`}
      className={`playing-card cardIn ${applyFoldedClassname ? " folded" : ""}`}
      style={{
        animationDelay: `${applyFoldedClassname ? 0 : animationDelay}ms`,
      }}
    >
      <h6
        style={{
          color: `${suit === "Diamond" || suit === "Heart" ? "red" : "black"}`,
        }}
      >
        {`${cardFace}`}
      </h6>
      {convertImage(suit)}
    </div>
  );
};

export default Card;
